// Constants
import { ROLES } from '@/constants'
// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyTabs from '@/components/ui/VuetifyTabs'
import UserForm from '../UserForm'
import UserManagerForm from '../UserManagerForm'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { getEnvironmentVariable } from '@/utils'
import { cloneDeep } from 'lodash'

export default {
  name: 'userByTabs',
  components: {
    VuetifyToolBar,
    VuetifyTabs
  },
  mixins: [uiMixin],
  data() {
    return {
      items: [
        {
          label: 'Perfil',
          component: UserForm
        }
      ]
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * Secciones actuales de las pestañas
     *
     * @return {Array}
     */
    currentItems() {
      const items = cloneDeep(this.items)

      if (
        getEnvironmentVariable('VUE_APP_BRAND') !== 'schweppes' &&
        this.userData.role === ROLES.horeca_admin.value
      ) {
        items.push({
          label: 'Gestión',
          component: UserManagerForm
        })
      }

      return items
    }
  },
  created() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      // Si el usuario es partner y aunque el enlace no aparezca
      // en el menú del usuario, por si es un listo, lo devolvemos al dashboard
      if (this.userData.isManagerUser) {
        this.routerPushTo({
          name: 'dashboard'
        })
      }
    }
  }
}
