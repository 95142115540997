// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserFom',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons
  },
  mixins: [validationMixin, formMixin, uiMixin],
  data() {
    return {
      // Form
      formFields: {
        name: null,
        email: null,
        password: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto'
        },
        password: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 6 caracteres'
        }
      },
      // Others
      changePassword: false, // to change password field
      togglePassword: false // to show or hide password field
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData'])
  },
  mounted() {
    this.setFormFieldsValues()
  },
  methods: {
    ...mapActions('authentication', ['updateUserAuth', 'updateUser']),
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      this.formFields = {
        ...this.formFields,
        name: this.userData.name,
        email: this.userData.email
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Actualizamos usuario en Auth
      await this.updateUserAuth({
        ...this.formFields,
        changePassword: this.changePassword
      })

      // Actualizamos usuario en BD
      // eslint-disable-next-line no-unused-vars
      const { password, ...params } = this.formFields // eliminamos password al salvar
      this.updateUser({ id: this.userData.id, ...params })

      // show info success
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {
        name: {
          required
        },
        email: {
          required,
          email
        }
      }
    }

    if (this.changePassword) {
      rules.formFields.password = {
        required,
        minLength: minLength(6)
      }
    }

    return rules
  }
}
