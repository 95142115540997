// Contants
import { ADDONS, COMPANY_RELATIONSHIP_TYPES, ROLES } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Services
import { cancelRelationship } from '@/services/relationships'
import { sendAnySendinblueEmail } from '@/services/email'
import { getCompanyById } from '@/services/company'
import { getEveryUsersByIds } from '@/services/user'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get } from 'lodash'

export default {
  name: 'UserManagerForm',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons
  },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      processingRequest: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('company', ['companyData']),
    ...mapGetters('place', ['placeData']),
    /**
     * Identificador del partner que se encuentra
     * gestionando actualmente la compañía
     *
     * @return {string}
     */
    currentPartnerId() {
      return this.companyData.partnerId
    }
  },
  methods: {
    ...mapActions('company', ['updateCompany', 'getCompany']),
    /**
     * copiar KEY (companyId) in clipboard
     */
    handleClickKeyToClipboard() {
      const companyIdInput = this.$refs.companyIdInput.$el.getElementsByTagName('INPUT')[0]
      companyIdInput.select()

      try {
        document.execCommand('copy')
        // show info
        this.modifyAppAlert({
          text: '¡La clave se ha copiado con éxito!',
          visible: true
        })
      } catch (err) {
        // show info
        this.modifyAppAlert({
          text: 'Ha ocurrido un error al copiar la clave',
          type: 'error',
          visible: true
        })
      }
    },
    /**
     * Lanzado cuando pulsamos sobre el botón "Eliminar"
     */
    async handleRemovePartner() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          try {
            // Loading
            this.processingRequest = true
            // Enviamos email al partner
            await this.sendEmailToPartner()
            // Eliminamos relación con el partner
            const { ok } = await cancelRelationship(
              this.companyData.id,
              COMPANY_RELATIONSHIP_TYPES.partner
            )

            if (!ok) {
              throw new Error('Hubo un error al intentar cancelar la relación.')
            }

            // Actualizamos datos de la compañía en Store
            await this.getCompany(this.companyData.id)

            // Mostramos mensaje
            this.modifyAppAlert({
              text: 'Proceso finalizado correctamente.',
              visible: true
            })
          } catch (error) {
            // show error
            this.modifyAppAlert({
              text: error.message,
              type: 'error',
              visible: true
            })
          } finally {
            // Loading
            this.processingRequest = false
          }
        },
        actionButtonText: 'Eliminar',
        text: '¿Estas seguro que deseas eliminar la relación con el "Partner"?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Enviamos email a la compañía partner
     */
    async sendEmailToPartner() {
      // Obtenemos datos de la compañía del partner
      const partnerCompany = await getCompanyById(this.currentPartnerId, {
        includeAddOnsConfigs: false,
        includeSubscriptions: false
      })
      // Obtenemos los usuarios de la compañía del partner
      const partnerCompanyUsers = await getEveryUsersByIds(Object.keys(partnerCompany.users))
      // Obtenemos el usuario partner de esta
      const partnerCompanyUser = partnerCompanyUsers.find(
        (user) =>
          user.role === ROLES.partner_partner.value || user.role === ROLES.partner_brand.value
      )

      if (partnerCompanyUser.email) {
        // Enviamos email
        await sendAnySendinblueEmail({
          method: 'cancelPartnerRelationshipRequest',
          email: partnerCompanyUser.email,
          name: 'Sistema automatizado',
          customParams: {
            companyId: this.companyData.id,
            emailUser: this.userData.email,
            placeName: get(this.placeAddonsSetupByUser, `${ADDONS.place}.name`, null)
          }
        })
      }
    }
  }
}
